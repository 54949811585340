<template>
  <div>
    <el-table
      :data="data"
      class="table-normal animate__animated animate__fadeIn animate__slow w-100"
      :class="data.length === 0 ? 'empty' : ''"
      v-loading="loading"
      element-loading-text="กำลังโหลดข้อมูล..."
      element-loading-spinner="el-icon-loading"
    >
      <template slot="empty">
        <img alt="logo" src="@/assets/logo.png" />
        <p>ไม่พบข้อมูล</p>
      </template>
      <el-table-column min-width="250" align="center" prop="companyName">
        <template slot="header">
          <div class="two-line">
            <p>Company name</p>
            <p>(ชื่อบริษัท)</p>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        min-width="150"
        align="left"
        :show-overflow-tooltip="true"
      >
        <template slot="header">
          <div class="two-line">
            <p>Certificated Scope</p>
            <p>(ขอบเขตใบรับรอง)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <span>
            {{ scope.row.certification.certificateScope }}
          </span>
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center">
        <template slot="header">
          <div class="two-line">
            <p>Standard</p>
            <p>(มาตรฐาน)</p>
          </div>
        </template>
        <template slot-scope="scope">
          {{ scope.row.certification.standard }}
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center">
        <template slot="header">
          <div class="two-line">
            <p>Registration No.</p>
            <p>(เลขที่ลงทะเบียน)</p>
          </div>
        </template>
        <template slot-scope="scope">
          {{ scope.row.certification.registrationNo }}
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center">
        <template slot="header">
          <div class="two-line">
            <p>First Issuing Date</p>
            <p>(วันที่ออกครั้งแรก)</p>
          </div>
        </template>
        <template slot-scope="scope">
          {{ scope.row.certification.firstIssuingDate | formatDateTH }}
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center">
        <template slot="header">
          <div class="two-line">
            <p>Expiration Date</p>
            <p>(วันที่หมดอายุ)</p>
          </div>
        </template>
        <template slot-scope="scope">
          {{ scope.row.certification.expiationDate | formatDateTH }}
        </template>
      </el-table-column>
      <el-table-column width="130px">
        <template slot="header">
          <div class="two-line">
            <p>Action</p>
            <p>(จัดการ)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <div class="box-icon">
            <i
              class="fas fa-eye"
              @click="
                $router.push(
                  `/certification/view/` + scope.row.certification._id
                )
              "
            ></i>
            <i
              class="fas fa-edit"
              @click="
                $router.push(
                  `/certification/edit/` + scope.row.certification._id
                )
              "
            ></i>
            <i
              class="fas fa-trash-alt keep"
              @click="$emit('openDialogTrash', scope.row.certification._id)"
            ></i>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      optionsStandardFood: (state) => state.optionAll.optionsStandardFood,
      optionsStandardIsoOne: (state) => state.optionAll.optionsStandardIsoOne,
      optionsStandardIsoTwo: (state) => state.optionAll.optionsStandardIsoTwo,
    }),
  },
  props: {
    data: {
      default: Array,
      return: [],
    },
    loading: {
      type: Boolean,
      return: false,
    },
  },
};
</script>
