<template>
  <DefaultTemplate
    :footer="true"
    v-loading.lock="loading"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
    id="certification-page"
  >
    <div>
      <h2 class="mg-y-2">Certification</h2>
      <el-row
        :gutter="15"
        class="mg-y-5 flex-wrap"
        type="flex"
        align="middle"
        justify="space-between"
      >
        <el-col :span="24" :sm="12" :md="6" :lg="6">
          <el-form ref="formFilter" :model="filter" label-position="top">
            <el-form-item label="Search">
              <el-input
                clearable
                placeholder="Search"
                v-model="filter.search"
                class="search"
                @input="searchAll()"
              >
                <i slot="prefix" class="fas fa-search font-14 mg-l-1"></i>
              </el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="24" :md="12" :xl="16" class="text-right">
          <el-button
            round
            @click="openHistory()"
            class="mg-t-2 font-14 button-history"
            ><i class="fas fa-history"></i> History log</el-button
          >

          <el-button
            type="warning"
            class="button-radius mg-t-2 font-14"
            @click="$router.push(`/certification/add`)"
            >+ Create Certification</el-button
          >
        </el-col>
      </el-row>
      <el-row :gutter="15">
        <el-col :span="24">
          <TableCertification
            :data="fetchData"
            :loading="loadingTable"
            @openDialogTrash="openDialogTrash"
          />
          <p class="text-right font-12 color-69">Total : {{ total }}</p>

          <div class="text-center mg-y-4">
            <el-pagination
              :hide-on-single-page="true"
              @size-change="handleSizeChange"
              @current-change="changePage"
              :current-page.sync="page"
              :page-size="limit"
              layout="prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog :visible.sync="dialogTrash" width="50%">
      <template slot="title">
        <p class="font-28 text-center mg-b-less color-main-1">ยืนยันการลบ</p>
      </template>
      <p class="text-center mg-t-less font-16 color-main-1">
        กรุณาตรวจสอบก่อนลบข้อมูล
      </p>
      <span slot="footer" class="dialog-footer is-flex js-between">
        <el-button @click="dialogTrash = false" class="button-radius"
          >ยกเลิก
        </el-button>
        <el-button
          type="warning"
          @click="submitDeleteCertification()"
          class="button-radius"
          >ยืนยัน
        </el-button>
      </span>
    </el-dialog>

    <el-drawer
      class="drawer-history"
      :visible.sync="drawerHistory"
      :before-close="closeHistory"
    >
      <div class="box-header">
        <div>
          <span> History log </span>
        </div>
      </div>
      <div id="custom-collapse" v-if="fetchHistory.length > 0">
        <virtual-list
          v-if="fetchHistory.length > 0"
          class="detail-log list-infinite scroll-touch"
          :data-key="'_id'"
          :data-sources="fetchHistory"
          :data-component="itemComponent"
          v-on:tobottom="scrollToBottom"
          :item-class="'list-item-infinite'"
        >
          <div slot="footer" class="text-center" v-if="loadingHistory">
            <i class="el-icon-loading"></i>
          </div>
        </virtual-list>
      </div>
      <p
        class="font-20 color-75 text-center mg-y-5 pd-t-1"
        v-if="fetchHistory.length == 0"
      >
        ไม่มีข้อมูล
      </p>
    </el-drawer>
  </DefaultTemplate>
</template>

<script>
import "@/mixin/DefaultTemplate";
import { HTTP } from "@/service/axios";
import TableCertification from "@/components/table/TableCertification";
import RequisitionDetail from "@/views/RequisitionDetail";
import VirtualList from "vue-virtual-scroll-list";
import History from "@/components/history/History";

import { mapState } from "vuex";

export default {
  components: {
    TableCertification,
    RequisitionDetail,
    "virtual-list": VirtualList,
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      user: (state) => state.user,
    }),
  },
  mounted() {
    if (this.user.role != 80) {
      this.router.go(-1);
    }
    this.getRequisition();
  },
  data() {
    return {
      loading: true,
      loadingTable: false,
      filter: {
        search: "",
      },
      fetchData: [],
      page: 1,
      skip: 0,
      limit: 10,
      total: 20,
      idActive: "",
      formGet: {},
      drawerHistory: false,
      fetchHistory: [],
      loadingHistory: false,
      totalAll: 0,
      skipHistory: 0,
      limitHistory: 10,
      pageNowHistory: 1,
      itemComponent: History,
      dialogTrash: false,
    };
  },
  methods: {
    getRequisition() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;

      let obj = {
        ...this.filter,
        skip: this.skip,
        limit: this.limit,
      };

      HTTP.post(`certification/all`, obj)
        .then((res) => {
          if (res.data.success) {
            this.fetchData = res.data.obj;
            this.total = res.data.total;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.loadingTable = false;
        });
    },
    searchAll() {
      let debounce = null;
      this.loadingTable = true;

      clearTimeout(debounce);
      debounce = setTimeout(() => {
        this.getRequisition();
      }, 600);
    },
    handleSizeChange(val) {
      this.limit = val;
      this.changePage(1);
      this.getRequisition();
    },
    changePage(page) {
      this.loadingTable = true;
      this.page = page;
      this.skip = page * this.limit - this.limit;
      this.getRequisition();
    },

    openHistory() {
      this.drawerHistory = true;
      this.getHistoryLog();
    },
    getHistoryLog(skip, limit, type) {
      let obj = {
        type: 4,
        skip: skip != undefined ? skip : this.skipHistory,
        limit: limit != undefined ? limit : this.limitHistory,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`user/history/manage`, obj)
        .then((res) => {
          if (res.data.success) {
            if (type != "scroll") {
              this.fetchHistory = res.data.obj;
            } else {
              res.data.obj.forEach((row) => {
                this.fetchHistory.push({ ...row });
              });
            }
            this.totalAll = res.data.total;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    closeHistory() {
      this.drawerHistory = false;
      this.skipHistory = 0;
      this.pageNowHistory = 1;
    },
    async scrollToBottom() {
      if (this.fetchHistory.length < this.totalAll) {
        this.skipHistory = this.pageNowHistory * this.limitHistory;
        this.loadingHistory = true;
        await this.getHistoryLog(this.skipHistory, this.limitHistory, "scroll");
        this.pageNowHistory++;
      } else {
        this.loadingHistory = false;
      }
    },
    openDialogTrash(id) {
      this.idActive = id;
      this.dialogTrash = true;
    },
    submitDeleteCertification() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;

      this.loadingTable = true;
      this.loadingTableKeep = true;
      HTTP.delete(`certification/` + this.idActive)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              title: "ลบ Certification สำเร็จ!",
              type: "success",
              customClass: "success",
            });
            this.getRequisition();
            this.getRequisitionKeep();
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.idActive = "";
          this.dialogTrash = false;
        });
    },
  },
};
</script>
